import Layout from "../components/publicLayout"
import CareersBanner from "../components/banner";
import CareersComponent from "../components/careers";


const Components = () => {
    return (
        <div>
            <Layout>
                <CareersBanner
                    title="Work with us"
                    content=" "
                />
                <CareersComponent />
            </Layout>
        </div>
    );
}

export default Components;