import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';


// Define the User interface
interface User {
  id: number;
  userAccount: string;
  username: string;
  userRole: number;
  createTime: string;
}

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  logout: () => void;  
}

const AuthContext = createContext<AuthContextType | null>(null);


export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const logout = () => {
    setUser(null);  
  };

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axios.get('/user/current');
        if (response.data && response.data.data) {
          setUser(response.data.data);
        } else {
          throw new Error('No user data available');
        }
      } catch (error) {
        console.error("Failed to fetch current user:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};



