import { useState, useEffect } from 'react';
import Sidebar from "../components/sidebar";
import AddUser from '../components/adduser';
import { fetchUsers } from '../services/userHandler';

interface User {
    id: number;
    userAccount: string;
    userRole: number;
    createTime: string;
    username: string;
  }
  
  const roleMapping: { [index: number]: string } = {
    0: 'Admin',
    1: 'Staff'
  };
  
  const UserManagement = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [showAddUsers, setAddUsers] = useState(false);
  
    useEffect(() => {
      const loadData = async () => {
        try {
          const fetchedUsers = await fetchUsers();
          setUsers(fetchedUsers);
        } catch (error) {
          console.error('Error loading users:', error);
        }
      };
  
      loadData();
    }, []);

    return (
        <div>
            <Sidebar />
            {showAddUsers && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                    <div className="max-w-4xl w-3/4">
                        <AddUser onClose={() => setAddUsers(false)} />
                    </div>
                </div>
            )}
            <div className="px-4 sm:px-6 lg:px-40">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
                <p className="mt-2 text-sm text-gray-700">
                    A list of all the users in the system.
                </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button
                    type="button"
                    className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => setAddUsers(true)}
                >
                    Add user
                </button>
                </div>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                            ID
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Full name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Email
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Role
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Createtime
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                            <span className="sr-only">Edit</span>
                        </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {users.map((user) => (
                        <tr key={user.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {user.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.username}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.userAccount}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{roleMapping[user.userRole]}</td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.createTime}</td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                Edit<span className="sr-only">, {user.id}</span>
                            </a>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
    );
}

export default UserManagement;
  