import TextBannerComponent from "../components/textbanner";
import ServicesBannerComponent from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/6.svg';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";
import AlliedHeader from "../components/alliedhealthbanner";


const Components = () => {
    return (
        <div>
            <Layout>
                <Banner
                    title="Allied Health"
                    content=""
                />
                <AlliedHeader />
              <TextBannerComponent 
                  title="Social Worker"
                  content={<>
                      A social worker assists individuals by providing comprehensive support and advocacy. They conduct assessments, offer counselling, develop personalized care plans, and connect clients to essential community services. Social workers offer emotional support, help navigate complex systems like the NDIS, and advocate for clients' rights and access to services. They also assist in issues, educate clients and families about available resources, and monitor progress to ensure that the support remains effective. Through these efforts, social workers empower individuals to achieve greater independence and improve their overall quality of life.
                           </>}
                  image={BP} 
              />
              <ServicesBannerComponent />
              <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;