import Layout from "../components/publicLayout"
import ContactBanner from "../components/banner";
import ContactComponent from "../components/contact";

const Components = () => {
    return (
        <div>
            <Layout>
                <ContactBanner
                    title="Feel free to contact us"
                    content=""
                />
                <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;