import { Link, useLocation } from 'react-router-dom';

function classNames(...classes : any) {
  return classes.filter(Boolean).join(' ');
}

const Banner = () => {
  const location = useLocation();
  const links = [
    { path: '/services/positive-behaviour-support-practitioner', label: 'Positive Behaviour Support Practitioner', baseColor: 'bg-pink-500', hoverColor: 'hover:bg-pink-400' },
    { path: '/services/occupational-therapist', label: 'Occupational Therapist', baseColor: 'bg-pink-600', hoverColor: 'hover:bg-pink-400' },
    { path: '/services/support-coordination', label: 'Support Coordination', baseColor: 'bg-pink-700', hoverColor: 'hover:bg-pink-500' },
    { path: '/services/social-worker', label: 'Social Worker', baseColor: 'bg-pink-800', hoverColor: 'hover:bg-pink-600' },
  ];

  function isCurrent(path : any) {
    return location.pathname === path;
  }

  return (
    <div className="grid grid-cols-4 w-full h-32">
      {links.map((link) => (
        <Link
          to={link.path}
          key={link.path}
          className={classNames(
            'flex flex-col justify-center items-center text-center text-white text-xs sm:text-base font-semibold transition-colors h-full',
            isCurrent(link.path) ? `${link.baseColor} ${link.hoverColor} scale-110` : `${link.baseColor} ${link.hoverColor}`,
          )}
          aria-current={isCurrent(link.path) ? 'page' : undefined}
        >
          {link.label}
        </Link>
      ))}
    </div>
  );  
};

export default Banner;
