import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

interface ProtectedRouteProps {
  element: React.ReactElement;
  allowedRoles: number[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, allowedRoles }) => {
  const { user } = useAuth();

  if (!user || !allowedRoles.includes(user.userRole)) {
    // Redirect to login page or unauthorized page
    
    return <Navigate to="/noauth" replace />;
  }

  return element;
};

export default ProtectedRoute;

