import TextBannerComponent from "../components/textbanner2";
import ServicesButton from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/5.svg';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";


const Components = () => {
    return (
        <div>
            <Layout>
                <Banner
                    title="Supported Independent Living"
                    content=""
                />
              <TextBannerComponent 
                  title=""
                  content={<>
                      Supported Independent Living (SIL) provides 24/7 constant support to help individuals manage everyday tasks. 
                      <br /><br />
                      SIL is typically suited for individuals with disabilities who require a higher level of support and assistance. This means these individuals need consistent help throughout the day and night, every day of the week. The support can vary depending on individual needs but generally includes personal care, meal preparation, household chores, health and medication management, and overnight support.

                                    </>}
                  image={BP}
              />
              <ServicesButton />
              <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;