import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import image5 from '../images/ndis.png';

export default function About() {
    return (
        <div className="relative isolate bg-white">
          <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
            <div className="relative px-6 pb-20 pt-24 sm:pt-20 lg:static lg:px-8 lg:py-15">
              <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
                  <svg
                    className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                        width={200}
                        height={200}
                        x="100%"
                        y={-1}
                        patternUnits="userSpaceOnUse"
                      >
                        <path d="M130 200V.5M.5 .5H200" fill="none" />
                      </pattern>
                    </defs>
                    <rect width="100%" height="100%" strokeWidth={0} fill="white" />
                    <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                      <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
                  </svg>
                </div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">Feel free to contact us</h2>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                We are always by your side, providing unwavering support, no matter the challenges or circumstances you face.
                </p>
                <dl className="mt-10 space-y-4 text-2xl font-medium leading-7 text-gray-600">
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Telephone</span>
                      <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>
                      <a className="hover:text-gray-900" href="tel:1300070768">
                        1300 070 768
                      </a>
                    </dd>
                  </div>
                  <div className="flex gap-x-4">
                    <dt className="flex-none">
                      <span className="sr-only">Email</span>
                      <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                    </dt>
                    <dd>
                      <a className="hover:text-gray-900" href="mailto:info@enhancedwellbeing.com.au">
                        info@enhancedwellbeing.com.au
                      </a>
                    </dd>
                  </div>
                </dl>
                <div className="mt-10 flex">
                  <img src={image5} alt="Image 1" className="h-40 w-atuo" />
                </div>   
              </div>
            </div>       
            <div className="px-6 pb-24 pt-6 sm:pb-32 sm:pt-20 lg:col-start-2 lg:px-8 lg:py-15">
              <div className="mx-auto max-w-2xl lg:mr-0 lg:max-w-lg">
                <p className="text-3xl font-bold tracking-tight text-gray-900">
                Empowering Independence
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                We have a team of skilled and enthusiastic staff. We offer a range of services, including Positive Behaviour Support Practitioners, Occupational Therapists, Support Coordination, Disability Support Workers, and Supported Independent Living assistance.
                <br /><br />
                Together, we will achieve significant milestones that empower your independence. We are eager to create a personalized therapy plan tailored to your unique needs and aspirations.
                </p>
              </div>
            </div>
          </div>
        </div>
      )
}
