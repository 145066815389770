import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from '../services/userHandler';

const SignInPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");

    try {
      const userData = await loginUser({ userAccount: email, userPassword: password });

      if (userData && userData.id) {
        const path = userData.userRole === 0 ? "/admin/user" : "/admin/dashboard";
        navigate(path, {
          state: {
            isLoggedIn: true,
            email: userData.username,
          }
        });
      } else {
        setError("Invalid email or password.");
      }
    } catch (error) {
      setError("Invalid email or password.");
    }
  };

  return (
   <div className="main" >
   <div className="border p-5 rounded-3xl shadow-lg mx-auto mt-20 bg-white max-w-md">
     <h1 className="text-2xl mb-4 text-center font-bold">Log in</h1>
     <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
       <div>
         <label
           htmlFor="email"
           className="block mb-2 text-sm font-medium text-gray-900"
         >
           Email Address
         </label>
         <input
           name="email"
           id="email"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5"
           required={true}
         />
       </div>
       <div>
         <label
           htmlFor="password"
           className="block mb-2 text-sm font-medium text-gray-900"
         >
           Password
         </label>
         <input
           type="password"
           name="password"
           id="password"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg block w-full p-2.5"
           required={true}
         />
       </div>
       <div>
         <button
           type="submit"
           className="w-full text-white bg-blue-600 hover:bg-blue-700 font-bold rounded-lg text-sm px-5 py-2.5 text-center"
         >
           Log in
         </button>
         {error && (
           <p className="text-red-500 text-sm text-center p-4">{error}</p>
         )}
       </div>
     </form>
   </div>
 </div>
 );
} 

export default SignInPage;