import { Link } from 'react-router-dom';
import ServicesBannerComponent from "../components/servicesbutton";
import BannerImg from '../images/img1.jpg'; 
import { ReactComponent as Icon1 } from '../images/4632796_acceptable_admissible_agreeable_approval_ok_icon.svg';
import { ReactComponent as Icon2 } from '../images/5998649_agreement_commitment_cooperation_deal_handshake_icon.svg';
import { ReactComponent as Icon3 } from '../images/11000136_responsible_culpable_protection_care_insurance_icon.svg';

const cards = [
  {
    name: 'Allied Health',
    description: 'Allied Health professionals deliver specialized support to help individuals achieve independence and improve their quality of life.',
    icon: Icon1,
    path: '/services/positive-behaviour-support-practitioner'
  },
  {
    name: 'Disability Support Worker',
    description: 'Support workers provide assistance and support to individuals who may need help with various aspects of daily living.',
    icon: Icon2,
    path: '/services/disability-support-worker'
  },
  {
    name: 'Supported Independent Living',
    description: 'Supported Independent Living (SIL) provides 24/7 constant support to help individuals manage everyday tasks.',
    icon: Icon3,
    path: '/services/supported-independent-living'
  },
]

export default function Example() {
    return (
      <div className="relative isolate overflow-hidden bg-gray-900 py-24 sm:py-32">
        <img
          src={BannerImg} 
          alt=""
          className="absolute inset-0 -z-20 h-full w-full object-cover object-right md:object-center"
        />
        <div className="absolute inset-0 bg-black opacity-20 -z-10"></div>
        <div className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl">
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu">
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-30"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-3xl lg:mx-0">
            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">Enhanced Wellbeing</h2>
            <p className="mt-6 font-bold text-lg leading-8 text-gray-100">
            Enhanced Wellbeing is committed to providing personalized, comprehensive support and care for individuals with complex needs across Australia. <br />
            </p>
            <p className="mt-3 font-bold text-2xl leading-8 text-gray-200">
            Enhanced Wellbeing is a Registered NDIS Provider.
            </p>
          </div>
          <div className="md:grid mx-auto mt-16 max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8">
            {cards.map((card) => (
              <Link to={card.path} key={card.name} className="flex gap-x-4 rounded-xl bg-custom-1/80 p-6 ring-1 ring-inset ring-white/10 mb-6 sm:mb-0">
                <card.icon className="h-14 w-14 flex-none text-indigo-400" aria-hidden="true" />
                <div className="text-base leading-7">
                  <h3 className="font-semibold text-black">{card.name}</h3>
                  <p className="mt-2 text-gray-900">{card.description}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0">
        <ServicesBannerComponent />
        </div>
      </div>
    );
  }
  