import { Link } from 'react-router-dom';

export default function Example() {
  return (
    <div className="relative isolate items-center gap-x-6 bg-gray-50 py-5" style={{ backgroundColor: 'rgba(247, 247, 247, 0.8)' }}>
      <div
        className="relative left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl opacity-75"
        aria-hidden="true"
      >
        {/* <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        />
      </div>
      <div
        className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl opacity-75"
        aria-hidden="true"
      >
        <div
          className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
          style={{
            clipPath:
              'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)',
          }}
        /> */}
      </div>
      <p className="text-center text-xl leading-10 text-gray-900 hover:text-blue-600 z-10">
        <Link to = "/services" className = "inline-block py-2 px-4 font-semibold text-white rounded transition duration-150 ease-in-out bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 hover:from-red-600 hover:via-pink-600 hover:to-purple-600 opacity-100">
          Explore all our services&nbsp;<span aria-hidden="true">&rarr;</span>
        </Link>
      </p>
      <div className="flex flex-1 justify-end">
      </div>
    </div>
  )
}
