import { PhoneIcon, EnvelopeIcon,} from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import image1 from '../images/a.png';
import image2 from '../images/b.jpg';
import image3 from '../images/biglogo.png';
import image4 from '../images/logondis.png';

const navigation = {
    contact: [
      { name: '1300 070 768', href: 'tel:1300070768',icon: PhoneIcon},
      { name: 'info@enhancedwellbeing.com.au', href: 'mailto:info@enhancedwellbeing.com.au',icon: EnvelopeIcon },
    ],
    services: [
      { name: 'Positive Behaviour Support Practitioner', path: '/services/positive-behaviour-support-practitioner' },
      { name: 'Supported Independent Living', path: '/services/supported-independent-living' },
      { name: 'Disability Support worker', path: '/services/disability-support-worker' },
      { name: 'Occupational Therapist', path: '/services/occupational-therapist' },
      { name: 'Social Worker', path: '/services/social-worker' },
      { name: 'Support Coordination', path: '/services/support-coordination' },
    ],
    links: [
      { name: 'Contact Us', path: '/contact' },
      { name: 'Careers', path: '/careers' },
      // { name: 'About NDIS', path: '/ndis' },
      { name: 'Current Opportunities', path: '/opportunities' },   
    ],
  }
  
  export default function Example() {
    return (
      <footer className="bg-custom-1" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-20 sm:pt-24 lg:px-8 lg:pt-12">
          <div className="xl:grid xl:grid-cols-3 xl:gap-">
            <div className="grid grid-cols-1 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-3 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">Services</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.services.map((item) => (
                      <li key={item.name}>
                        <Link to={item.path} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-sm font-semibold leading-6 text-gray-900">Useful Links</h3>
                  <ul role="list" className="mt-6 space-y-4">
                    {navigation.links.map((item) => (
                      <li key={item.name}>
                        <Link to={item.path} className="text-sm leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  {/* <h3 className="text-sm font-semibold leading-6 text-gray-900"></h3> */}
                  <ul role="list" className="mt-0 space-y-4">
                    {navigation.contact.map((item) => (
                      <li key={item.name}>
                        <item.icon className="h-5 w-5 text-gray-600" aria-hidden="true" />
                        <a href={item.href} className="text-bg leading-6 text-gray-600 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-10 xl:mt-0">
              <h3 className="text-sm font-semibold leading-6 text-gray-900">Subscribe to our newsletter</h3>
              <p className="mt-2 text-sm leading-6 text-gray-600">
                Stay informed with updates on major events at Enhanced Wellbeing, directly delivered to your inbox.
              </p>
              <form action="https://formspree.io/f/xqkrrzbq" method="POST" className="mt-6 sm:flex sm:max-w-md">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  name="email-address"
                  id="email-address"
                  autoComplete="email"
                  required
                  className="w-full min-w-0 appearance-none rounded-md border-0 bg-white px-3 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:w-64 sm:text-sm sm:leading-6 xl:w-full"
                  placeholder="Enter your email"
                />
                <div className="mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                  <button
                    type="submit" 
                    className="flex w-full items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <div className="mt-10 flex">
              <img src={image3} alt="Image 1" className="h-20 w-atuo mr-8" /> 
              <img src={image4} alt="Image 2" className="h-20 w-auto" />
            </div>
            </div>
            {/* <div className="flex">
              <img src={image3} alt="Image 1" className="h-20 w-20 mr-8" /> 
              <img src={image4} alt="Image 2" className="h-20 w-20" />
            </div> */}
          </div>
          <div className="flex items-center bg-custom-1 px-6 py-5 sm:px-3.5 sm:before:flex-1">
            <p className="flex items-center text-xs leading-6 text-black">
                <img src={image1} alt="Image 1" className="h-5 w-8" />
                <img src={image2} alt="Image 2" className="h-5 w-8 mr-2" />
                In the spirit of reconciliation, Enhanced Wellbeing recognizes the Traditional Custodians of the lands across Australia and their enduring connection to the land, sea, and community. We honor their elders, both past and present, and extend our respect to all Aboriginal and Torres Strait Islander peoples today.
            </p>
            <div className="flex flex-1 justify-end">
            </div>
        </div>
          <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 md:flex md:items-center md:justify-between lg:mt-10">
            <p className="mt-8 text-xs leading-5 text-gray-500 md:order-1 md:mt-0">
              &copy; 2024 Enhanced Wellbeing. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    )
  }
  
