import { useState, useEffect } from 'react';
import Sidebar from "../components/sidebar";
import { fetchMessages, deleteMessage } from '../services/msgHandler';

interface Massage {
  id: number;
  fullName: string;
  postcode: number | null;
  email: string;
  phone: string;
  msg: string;
}

const Msgs = () => {
  const [messages, setMessages] = useState<Massage[]>([]);

  useEffect(() => {
    const loadData = async () => {
      try {
        const msgs = await fetchMessages();
        setMessages(msgs);
      } catch (error) {
        console.error('Error loading messages');
      }
    };

    loadData();
  }, []);

  return (
    <div>
      <Sidebar />
      <div className="px-4 sm:px-6 lg:px-40">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Messages</h1>
            <p className="mt-2 text-sm text-gray-700">The information collected in 'Contact Us'.</p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Full name</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Postcode</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Phone</th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Message</th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-0"><span className="sr-only">Delete</span></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {messages.map((msg) => (
                  <tr key={msg.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{msg.fullName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{msg.postcode}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{msg.email}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{msg.phone}</td>
                    <td className="break-words whitespace-normal max-w-xs px-3 py-4 text-sm text-gray-500">{msg.msg}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                      <button
                        onClick={() => deleteMessage(msg.id)}
                        className="text-red-600 hover:text-red-800"
                        type="button"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Msgs;
