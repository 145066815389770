import React from 'react';

interface TextBannerProps {
  title: string;
  content: React.ReactNode;
  image?: string;
  services?: string[];
  button?
}

function TextBannerComponent({ title, content, image, button}: TextBannerProps) {
  return (
    <div className="px-6 py-12 sm:py-16 lg:px-8 bg-gradient-to-r from-custom-4 to-custom-3 shadow-xl">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 max-w-6xl mx-auto items-center">
      {image && (
            <div className="flex justify-center lg:justify-end lg:col-span-1">
              <img
                src={image}
                alt="Banner Image"
                className="mt-4 w-48 lg:w-80 h-auto object-cover" 
              />
            </div>
                )}
            <div className="lg:col-span-2 text-center">
              <h2 className="text-4xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                {title}
              </h2>
              <div className="mt-6 text-lg leading-8 text-gray-900 text-left">
                {content}
              </div>    
            </div>                
          </div>
          {button && <div className="pt-16 banner-button">{button}</div>}
        </div>
  );
}
export default TextBannerComponent;





  