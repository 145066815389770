// core components
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import NdisBanner from "../components/banner";
import QAComponent from "../components/QA";
import Referrals from "../components/textbanner";
import { Link } from 'react-router-dom';
import Layout from "../components/publicLayout"


const Components = () => {
    return (
        <div>
            <Layout>
            <NdisBanner
                title="NDIS"
                content="Applying to join the NDIS is the first step in your journey to accessing supports. Here’s some key information to help you understand how the NDIS works."
            />
            <QAComponent />
            <Referrals
                title="Make Referrals"
                content={
                    <>
                    <p>About referrals.</p><br />
                    <div className="flex justify-center">
                        <Link to="/referral-link" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            Make a Referral
                        </Link>
                    </div>
                    </>
                }
                />
            </Layout>
            
        </div>
    );
}

export default Components;