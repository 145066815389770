import { useState } from 'react'
import JobComponent from "../components/jobinfo";

const filters = [
  {
    id: 'Employment type',
    name: 'Employment type',
    options: [
      { value: 'Casual', label: 'Casual' },
      { value: 'Full Time', label: 'Full Time' },
      { value: 'Part Time', label: 'Part Time' },
      { value: 'Subcontractor', label: 'Subcontractor' },
    ],
  },
  {
    id: 'location',
    name: 'Location',
    options: [
      { value: 'Adelaide', label: 'Adelaide' },
      { value: 'Victor Harbor', label: 'Victor Harbor' },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [selectedFilters, setSelectedFilters] = useState({
    location: [],
    type: []
  });

  const handleFilterChange = (filterName, value, checked) => {
    setSelectedFilters(prev => {
      const currentValues = prev[filterName] || [];  
      if (checked) {
        return { ...prev, [filterName]: [...currentValues, value] };
      } else {
        return { ...prev, [filterName]: currentValues.filter(item => item !== value) };
      }
    });
  };

  return (
    <div className="bg-gradient-to-r from-custom-4 to-custom-3">      
        <main className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
          <div className="border-b border-gray-200 pb-10">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">Join Us</h1>
            <p className="mt-4 text-base text-gray-500">
            We are a destination for career cultivation with opportunities. Explore the variety of careers we offer across all facets of healthcare. To apply for a position, please email your cover letter and resume to us.
            </p>
          </div>

          <div className="pt-12 lg:grid lg:grid-cols-3 lg:gap-x-8 xl:grid-cols-4">
            <aside>
              <h2 className="sr-only">Filters</h2>
              <div className="hidden lg:block">
                <form className="space-y-10 divide-y divide-gray-200">
                  {filters.map((section, sectionIdx) => (
                    <div key={section.name} className={sectionIdx === 0 ? null : 'pt-10'}>
                      <fieldset>
                        <legend className="block text-sm font-medium text-gray-900">{section.name}</legend>
                        <div className="space-y-3 pt-6">
                          {section.options.map((option, optionIdx) => (
                            <div key={option.value} className="flex items-center">
                              <input
                                      id={`${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      defaultValue={option.value}
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      onChange={(e) => handleFilterChange(section.id, option.value, e.target.checked)}
                                    />
                              <label htmlFor={`${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  ))}
                </form>
              </div>
            </aside>
            <div className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3"><JobComponent selectedFilters={selectedFilters} /></div>
          </div>
        </main>
      </div>

  )
}
