import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import Aboriginal from "../components/aboriginal";


function MainLayout({ children }: { children: React.ReactNode }) {
    return (
        <div>
            <HeaderComponent />
            {children} 
            {/* <Aboriginal /> */}
            <FooterComponent  />
        </div>
    );
}

export default MainLayout;