import BannerComponent from "../components/homebanner";
import TextBannerComponent from "../components/textbanner";
import TextBannerComponent2 from "../components/about";
import BP from '../images/biglogo.png'; 
import Layout from "../components/publicLayout"

const Components = () => {
    return (
        <div>
            <Layout>
                <BannerComponent />
                <TextBannerComponent 
                    title="Our Mission"
                    content={<>
                        We are excited to share our dedication and expertise in supporting individuals with complex needs in Australia. Our main focus is to provide all-encompassing care by understanding and incorporating our clients' goals, family, and support system. We are committed to going above and beyond to meet your needs.<br /><br />
                        We deliver our services directly to clients in their homes or community locations like schools, workplaces, and residential facilities. Additionally, our telehealth services are available throughout Australia.
                    </>}
                    image={BP}
                />
                <TextBannerComponent2 /> 
            </Layout>
        </div>
    );
}

export default Components;