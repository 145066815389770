import TextBannerComponent from "../components/textbanner";
import ServicesBannerComponent from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/3.svg';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";
import AlliedHeader from "../components/alliedhealthbanner";


const Components = () => {
    return (
        <div>
            <Layout>
                <Banner
                    title="Allied Health"
                    content=""
                />
                <AlliedHeader />
              <TextBannerComponent 
                  title="Support Coordination"
                  content={<>
                      Support Coordinator plays an integral role for NDIS participants in interpreting their NDIS plan, assisting them to navigate and access the services and support outlined in their NDIS plan or other funding arrangements. 
                           </>}
                  image={BP} 
              />
              <ServicesBannerComponent />
              <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;