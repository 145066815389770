import ServicesComponent from "../components/services";
import Layout from "../components/publicLayout"
import Banner from "../components/banner";

const Services = () => {
    return (
        <div>
            <Layout>
            <Banner
                    title="Our services"
                    content=""
                />
                <ServicesComponent />
            </Layout>
        </div>
    );
}

export default Services;