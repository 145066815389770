import {sendContactMessage } from '../services/msgHandler';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import image5 from '../images/ndis.png';



export default function ContactForm() {
  // const handleSubmit = async (event: any) => {
  //   event.preventDefault();
  //   const formData = new FormData(event.target);

  //   const fullName = formData.get('fullName')?.toString() || '';
  //   const postcodeRaw = formData.get('postcode')?.toString();
  //   const postcode = postcodeRaw ? parseInt(postcodeRaw, 10) : null;
  //   const email = formData.get('email')?.toString() || '';
  //   const phone = formData.get('phone')?.toString() || '';
  //   const msg = formData.get('message')?.toString() || '';

  //   if (fullName && email && phone && msg) {
  //     const data = { fullName, postcode, email, phone, msg };
  //     await sendContactMessage(data);
  //   } else {
  //     alert("Please fill out all fields.");
  //   }
  // };

  return (
    <div className="relative isolate bg-white">
      <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-2">
        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 lg:py-20">
          <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              {/* Background SVG and other UI elements */}
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Get in touch</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We are always by your side, providing unwavering support, no matter the challenges or circumstances you face. You can send us a message here to inquire.
            </p>
            <dl className="mt-10 space-y-4 text-2xl font-medium leading-7 text-gray-600">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telephone</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-gray-900" href="tel:1300070768">
                    1300 070 768
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-gray-900" href="mailto:info@enhancedwellbeing.com.au">
                    info@enhancedwellbeing.com.au
                  </a>
                </dd>
              </div>
                <div className="mt-10 flex">
                  <img src={image5} alt="Image 1" className="h-40 w-atuo" />
                </div>   
            </dl>
          </div>
        </div>       
        <form action="https://formspree.io/f/xnqkvpel" method="POST" className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-20">
          <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg">
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
              <div>
                <label htmlFor="fullName" className="block text-sm font-semibold leading-6 text-gray-900">
                  Your name*
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    autoComplete="name"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="postcode" className="block text-sm font-semibold leading-6 text-gray-900">
                  Your postcode
                </label>
                <div className="mt-2.5">
                  <input
                    type="text"
                    name="postcode"
                    id="postcode"
                    autoComplete="postal-code"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  Your Email*
                </label>
                <div className="mt-2.5">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    autoComplete="email"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="phone" className="block text-sm font-semibold leading-6 text-gray-900">
                  Your Phone number*
                </label>
                <div className="mt-2.5">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    autoComplete="tel"
                    required
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                  Message*
                </label>
                <div className="mt-2.5">
                  <textarea
                    name="message"
                    id="message"
                    required
                    rows={4}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Send message
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}