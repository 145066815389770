import LogoutButton from '../services/handleLogout';

const Staff = () => {
    return (
        <div>
            The staff page has not been developed yet!
            <LogoutButton />
        </div>
    );
}

export default Staff;