import Layout from "../components/publicLayout"
import Banner from "../components/banner";
import Filter from "../components/sidebarfilter";


const Components = () => {
    return (
        <div>
            <Layout>
            <Banner
                    title="Current Opportunities"
                    content=""
                />
                <div>
                  <Filter />
                </div>
            </Layout>
        </div>
    );
}

export default Components;