import axios from 'axios';

interface SignUpData {
  userAccount: string;
  username: string;
  userPassword: string;
  checkPassword: string;
}

interface LoginResponse {
  id: number;
  username: string;
  userRole: number;
}

interface LoginData {
  userAccount: string;
  userPassword: string;
}

interface User {
  id: number;
  userAccount: string;
  userRole: number;
  createTime: string;
  username: string;
}

export const registerNewUser = async (signUpData: SignUpData): Promise<any> => {
  try {
    const response = await axios.post("/user/newuser", signUpData);
    return response.data;
  } catch (error) {
    throw new Error("An error occurred while registering. Please try again later.");
  }
};

export const loginUser = async (loginData: LoginData): Promise<LoginResponse> => {
  try {
    const response = await axios.post('/user/login', loginData);
    return response.data.data;
  } catch (error) {
    throw new Error("Invalid email or password.");
  }
};

export const fetchUsers = async (): Promise<User[]> => {
  try {
    const response = await axios.get('/user/list');
    return response.data.data as User[];
  } catch (error) {
    console.error('Failed to fetch users:', error);
    throw error; 
  }
};
