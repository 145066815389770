import TextBannerComponent from "../components/textbanner";
import ServicesBannerComponent from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/1.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";
import AlliedHeader from "../components/alliedhealthbanner";

const servicesA = [
  "Comprehensive/Interim Behaviour Support Plan",
  "Functional Behaviour Assessment ",
  "Training for support network ",
  "Recommendation Letter",
  "Risk Assessment",
  "Continuously monitoring and reviewing the plan's effectiveness.",
  "Improving the individual's environment to decrease challenging behaviors and enhance their overall well-being.",
  "Evaluating and adjusting any restrictive practices in line with NDIS regulations to minimize or eliminate their use."
];

const Components = () => {
    return (
        <div>
            <Layout>
                <Banner
                    title="Allied Health"
                    content=""
                />
                <AlliedHeader />
              <TextBannerComponent 
                  title="Positive Behaviour Support Practitioner"
                  content={<>
                      A Behavior Support Practitioner is a professional who works with individuals who exhibit challenging behaviors due to various factors. Our primary role is to assess, design, and implement behavior support plans to help individuals manage their behaviors more effectively and improve their overall quality of life.<br /><br />
                      We provide a wide range of NDIS BSP services including:
                      <ul className="list-none pl-0 space-y-2 text-gray-800">
                        {servicesA.map(service1 => (
                          <li key={service1} className="flex items-start">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                            <span>{service1}</span>
                          </li>
                        ))}
                      </ul>
                                    </>}
                  image={BP}
              />
              <ServicesBannerComponent />
              <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;