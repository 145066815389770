import HomePage from './pages/home';

const App = () => {
  return (
    <div className="App">
        <HomePage />
    </div>
  );
};

export default App;
