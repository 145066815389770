import { Link } from 'react-router-dom';
import image1 from '../images/1.svg';
import image2 from '../images/2.svg';
import image3 from '../images/3.svg';
import image4 from '../images/4.svg';
import image5 from '../images/5.svg';
import image6 from '../images/6.svg';

const posts = [
  {
    title: 'Positive Behaviour Support Practitioner',
    path: '/services/positive-behaviour-support-practitioner',
    description:
      'A Behavior Support Practitioner is a professional who works with individuals who exhibit challenging behaviors due to various factors. Our primary role is to assess, design, and implement behavior support plans to help individuals manage their behaviors more effectively and improve their overall quality of life.',
    image: image1,
  },
  {
    title: 'Supported Independent Living',
    path: '/services/supported-independent-living',
    description:
      'SIL is typically suited for individuals with disabilities who require a higher level of support and assistance. This means these individuals need consistent help throughout the day and night, every day of the week. The support can vary depending on individual needs but generally includes personal care, meal preparation, household chores, health and medication management, and overnight support.',
    image: image5,
  },
  {
    title: 'Occupational Therapist',
    path: '/services/occupational-therapist',
    description:
      'Occupational Therapy (OT) involves working with clients of all ages and abilities to improve independence, manage day to day tasks and achieve life goals. We offer support related to performing tasks such as showering, cooking, living at home independently, shopping, learning or working. We help you engage in all the activities that matter to you the most. ',
    image: image2,
  },
  {
    title: 'Disability Support worker',
    path: '/services/disability-support-worker',
    description:
      'A support worker provides assistance and support to individuals who may need help with various aspects of daily living. The specific tasks and responsibilities of a support worker can vary depending on the needs and goals of the individual they are assisting.',
    image: image4,
  },
  {
    title: 'Support Coordination',
    path: '/services/support-coordination',
    description:
      'Support Coordinator plays an integral role for NDIS participants in interpreting their NDIS plan, assisting them to navigate and access the services and support outlined in their NDIS plan or other funding arrangements. ',
    image: image3,
  },
  {
    title: 'Social Worker',
    path: '/services/social-worker',
    description:
      "Social workers assess and support individuals by offering counseling, creating care plans, and connecting them to community services. They navigate systems like the NDIS, advocate for rights, educate on resources, and monitor progress to enhance clients' independence and life quality.",
    image: image6,
  },


]

export default function Example() {
  return (
    <div className="bg-gradient-to-r from-custom-4 to-custom-3 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What support are you looking for?</h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
          We have a team of skilled and enthusiastic allied health professionals, we offer a range of services.
          </p>
        </div>
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <article key={post.title} className="flex flex-col items-start">
            <div className="relative w-full">
              <img
                src={post.image}
                alt=""
                className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-contain sm:aspect-[2/1] lg:aspect-[3/2]"
              />
              <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div className="max-w-xl mt-3">  
              <div className="group relative">
                <h3 className="text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                  <Link to={post.path}>
                    <span className="absolute inset-0" />
                    {post.title}
                  </Link>
                </h3>
                <p className="mt-3 text-sm leading-6 text-gray-600">{post.description}</p>
              </div>
            </div>
          </article>
          ))}
        </div>
      </div>
    </div>
  )
}