import { Link } from 'react-router-dom';
import TextBannerComponent from "../components/textbanner2";
import BP from '../images/biglogo.png';

export default function Example() {
  return (
    <div className="mx-auto w-full text-center">
      <TextBannerComponent 
        title=""
        content={<>
          At Enhanced Wellbeing, we are not just a healthcare organization; we are a beacon for career cultivation. With a broad spectrum of opportunities spanning the entire healthcare industry, we invite you to explore and contribute to a variety of roles that impact lives and foster personal and community well-being.
          <br /><br />
          We are eagerly seeking individuals who are driven to support and inspire. Whether you are beginning your career or are an experienced professional, we offer a dynamic environment where your work is meaningful and your contributions significant. Together, we will break new ground and advance our mission of empowering independence through dedicated and compassionate healthcare.
        </>}
        image={BP}
        button={
          <Link to="/opportunities" className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
            View Current Opportunities
          </Link>
        }
      />
    </div>
  )
}

  