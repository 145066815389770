import React, { useState } from 'react';

const posts = [
  // {
  //   id: 1,
  //   title: 'Support Worker',
  //   description: [
  //     'Discover the latest tips and tricks about SEO. Learn how to effectively boost your website traffic and engagement.',
  //     'Explore advanced strategies that can help you optimize your site for better visibility and higher rankings in search engine results.',
  //     'Gain insights into tools and techniques that professionals use to enhance their SEO efforts and achieve sustainable growth.'
  //   ],
  //   location: 'Adelaide',
  //   type: 'Full Time',
  // },
  // {
  //   id: 2,
  //   title: 'Occupational Therapist',
  //   description: [
  //     'Discover the latest tips and tricks about SEO. Learn how to effectively boost your website traffic and engagement.',
  //     'Explore advanced strategies that can help you optimize your site for better visibility and higher rankings in search engine results.',
  //     'Gain insights into tools and techniques that professionals use to enhance their SEO efforts and achieve sustainable growth.',
  //     'Additional details and information about the role and responsibilities.'
  //   ],
  //   location: 'Victor Harbor',
  //   type: 'Casual',
  // },
  // {
  //   id: 3,
  //   title: 'Occupational Therapist2',
  //   description: [
  //     'Discover the latest tips and tricks about SEO. Learn how to effectively boost your website traffic and engagement.',
  //     'Explore advanced strategies that can help you optimize your site for better visibility and higher rankings in search engine results.',
  //     'Gain insights into tools and techniques that professionals use to enhance their SEO efforts and achieve sustainable growth.',
  //     'Additional details and information about the role and responsibilities.'
  //   ],
  //   location: 'Adelaide',
  //   type: 'Subcontractor',
  // },
  // {
  //   id: 4,
  //   title: 'Occupational Therapist3',
  //   description: [
  //     'Discover the latest tips and tricks about SEO. Learn how to effectively boost your website traffic and engagement.',
  //     'Explore advanced strategies that can help you optimize your site for better visibility and higher rankings in search engine results.',
  //     'Gain insights into tools and techniques that professionals use to enhance their SEO efforts and achieve sustainable growth.',
  //     'Additional details and information about the role and responsibilities.'
  //   ],
  //   location: 'Adelaide',
  //   type: 'Part Time',
  // }
];

export default function Example({ selectedFilters }) {
  const { location: locations = [], 'Employment type': types = [] } = selectedFilters; 
  const filteredPosts = posts.filter(post =>
    (locations.length === 0 || locations.includes(post.location)) &&
    (types.length === 0 || types.includes(post.type))
  );

  const [expandedState, setExpandedState] = useState({});  // State to manage the expansion of description

  const toggleExpanded = (id) => {
    setExpandedState(prevState => ({
      ...prevState,
      [id]: !prevState[id]  // Toggle the expanded state for each post
    }));
  };

  const handleApplyNow = (post) => {
    const subject = encodeURIComponent(`Application for ${post.title}`);
    const body = encodeURIComponent(`I am interested in applying for the position of ${post.title} located in ${post.location} as a ${post.type}. Please find my application attached.`);
    window.location.href = `mailto:info@enhancedwellbeing.com.au?subject=${subject}&body=${body}`;
  };

  return (
    <div className="w-full bg-white bg-opacity-60 py-0 sm:py-0">
      {filteredPosts.map((post) => (
        <article key={post.id} className="w-full flex flex-col items-start justify-between mb-4 p-4 shadow rounded-lg">
          <div className="flex items-center gap-x-4 text-sm">
            <p className="rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
              {post.location}
            </p>
            <p className="rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100">
              {post.type}
            </p>
          </div>
          <div className="relative">
            <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900">
              {post.title}
            </h3>
            <div className={`text-sm leading-6 text-gray-600 mt-2 ${!expandedState[post.id] ? 'line-clamp-3' : ''}`}>
              {post.description.map((paragraph, index) => (
                <div key={index}>
                  <p>{paragraph}</p>
                  {index < post.description.length - 1 ? <br /> : null}
                </div>
              ))}
            </div>
            {expandedState[post.id] && (
              <div className="flex flex-col items-start mt-2">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-base"
                  onClick={() => handleApplyNow(post)}>
                  Apply Now
                </button>
                <button onClick={() => toggleExpanded(post.id)} className="mt-1 text-blue-500 hover:text-blue-700 text-sm">
                  Show Less
                </button>
              </div>
            )}
            {!expandedState[post.id] && (
              <button onClick={() => toggleExpanded(post.id)} className="text-blue-500 hover:text-blue-700 text-sm mt-2">
                Show More
              </button>
            )}
          </div>
        </article>
      ))}
    </div>
  );
}