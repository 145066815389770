import Sidebar from "../components/sidebar";

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
  ]

const Components = () => {
    return (
        <div>
            <Sidebar />      
        </div>
    );
}

export default Components;
  