import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import './index.css';
import Home from './pages/home';
import ServiceA from './pages/serviceA';
import ServiceB from './pages/serviceB';
import ServiceC from './pages/serviceC';
import ServiceD from './pages/serviceD';
import ServiceE from './pages/serviceE';
import ServiceF from './pages/serviceF';
import Ndis from './pages/ndis';
import Contact from './pages/contact';
import Services from './pages/services';
import Careers from './pages/careers';
import App from './App';
import Opportunities from './pages/opportunities';
import ScrollToTop from './components/scroll';
import NoMatch from './pages/404';
import Login from './pages/login';
import Msg from './pages/msg';
import User from './pages/user';
import Recruit from './pages/recruit';
import Dashboard from './pages/staff';
import NoAuth from './pages/noAuth';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute'; 

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


root.render(
  <React.StrictMode>
     <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/ndis" element={<Ndis />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route path="/services/positive-behaviour-support-practitioner" element={<ServiceA />} />
        <Route path="/services/occupational-therapist" element={<ServiceB />} />
        <Route path="/services/support-coordination" element={<ServiceC />} />
        <Route path="/services/disability-support-worker" element={<ServiceD />} />
        <Route path="/services/supported-independent-living" element={<ServiceE />} />
        <Route path="/services/social-worker" element={<ServiceF/>} />
        <Route path="/admin" element={<Login />} />
        <Route path="/noauth" element={<NoAuth />} />
        <Route path="*" element={<NoMatch />} />
        <Route path="/admin/*" element={
          <AuthProvider>
            <Routes>
              <Route path="msg" element={<ProtectedRoute element={<Msg />} allowedRoles={[0]} />} />
              <Route path="user" element={<ProtectedRoute element={<User />} allowedRoles={[0]} />} />
              <Route path="recruit" element={<ProtectedRoute element={<Recruit />} allowedRoles={[0]} />} />
              <Route path="dashboard" element={<ProtectedRoute element={<Dashboard />} allowedRoles={[0, 1]} />} />
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </AuthProvider>
        } />
      </Routes>
    </Router>
  </React.StrictMode>
);
