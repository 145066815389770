import TextBannerComponent from "../components/textbanner";
import ServicesBannerComponent from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/4.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";

import { useState } from 'react';


const ServiceD = () => {
  const [activeService, setActiveService] = useState(1);
  const services = [
    { id: 1, label: 'Community participation', baseColor: 'bg-pink-500', hoverColor: 'hover:bg-pink-700' },
    { id: 2, label: 'Personal Care', baseColor: 'bg-pink-600', hoverColor: 'hover:bg-pink-800' },
    { id: 3, label: 'Household Tasks', baseColor: 'bg-pink-700', hoverColor: 'hover:bg-pink-900' },
    { id: 4, label: 'Mobility Support', baseColor: 'bg-purple-500', hoverColor: 'hover:bg-purple-700' },
    { id: 5, label: 'Meal Preparation', baseColor: 'bg-purple-600', hoverColor: 'hover:bg-purple-800' },
    { id: 6, label: 'Companionship', baseColor: 'bg-purple-700', hoverColor: 'hover:bg-purple-900' },
  ];
  const servicesD1 = ['Accompany clients to social events, community centers, or recreational activities.',
  'Assist with transportation to and from these activities.', 
  'Aid in toileting and continence care.',
  'Guide clients in learning social skills or interacting with others.']; 
  const servicesD2 = ['Assist with bathing, showering, or grooming.',
  'Help clients dress and undress, including managing adaptive clothing or prosthetics.', 
  'Help clients connect with volunteer opportunities or employment support services.',
  'Provide support with oral hygiene, hair care, and other personal grooming tasks.'];   
  const servicesD3 = ['Perform light housekeeping, such as vacuuming, dusting, and mopping.',
  'Assist with laundry, including washing, drying, folding, and ironing.', 
  'Help with organizing personal belongings and general tidying.',
  'Support with other tasks like changing bed linens or taking out the trash.'];
  const servicesD4 = ['Assist with using mobility aids such as wheelchairs, walkers, or crutches.',
  'Help clients navigate their home, preventing falls or other hazards.', 
  'Aid in transportation to medical appointments or other necessary outings.',
  'Provide physical support during transitions, such as from a bed to a wheelchair.'];
  const servicesD5 = ['Help plan meals that meet dietary requirements or preferences.',
  'Prepare meals, ensuring proper cooking and food safety.', 
  'Assist with feeding if needed.',
  'Manage food shopping and stockpiling of groceries.'];
  const servicesD6 = ["Engage in meaningful conversations and listen to clients' thoughts and concerns.",
  "Participate in shared activities, such as watching movies, playing games, or going for walks.", 
  'Provide emotional support during challenging times or medical procedures.',
  'Encourage participation in social activities to foster connections with others.'];                
  

  const handleServiceClick = (serviceId :any) => {
    setActiveService(serviceId);
  };

  return (
    <Layout>
      <Banner
          title="Disability Support worker"
          content=""
      />
        <div className="flex flex-wrap w-full">
          {services.map((service) => (
            <div 
              key={service.id} 
              className={`w-1/3 h-32 flex justify-center items-center text-center text-white text-base sm:text-xl font-semibold transition-colors 
              ${service.id === activeService ? `${service.baseColor} ${service.hoverColor} scale-110` : `${service.baseColor} ${service.hoverColor}`}`
            }  
              onClick={() => handleServiceClick(service.id)}
              aria-current={service.id === activeService ? 'page' : undefined}
            >
              {service.label}
            </div>
          ))}
          </div>
          {activeService === 1 && <TextBannerComponent 
          title="Community participation"
          content={<>
              Community participation involves assisting clients in engaging with their community, promoting social inclusion, and helping them access community resources. 
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD1.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
        {activeService === 2 && <TextBannerComponent 
          title="Personal Care"
          content={<>
              Personal care entails providing direct assistance with personal hygiene and self-care activities. This support ensures clients maintain cleanliness, comfort, and dignity.
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD2.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
        {activeService === 3 && <TextBannerComponent 
          title="Household Tasks"
          content={<>
              Household tasks encompass activities that keep the client's living environment clean and organized. 
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD3.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
        {activeService === 4 && <TextBannerComponent 
          title="Mobility Support"
          content={<>
              Mobility support involves aiding clients in moving around safely within their environment, whether indoors or outdoors. 
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD4.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
        {activeService === 5 && <TextBannerComponent 
          title="Meal Preparation"
          content={<>
              Meal preparation is crucial for clients who need assistance with planning, preparing, and sometimes feeding. 
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD5.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
        {activeService === 6 && <TextBannerComponent 
          title="Companionship"
          content={<>
              Companionship involves providing emotional support and ensuring clients do not feel isolated or lonely. 
              <ul className="list-none pl-0 space-y-2 text-gray-800">
                {servicesD6.map(service => (
                  <li key={service} className="flex items-start">
                    <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                    <span>{service}</span>
                  </li>
                ))}
              </ul>
              </>}
          image={BP}
        />}
      <ServicesBannerComponent />
      <ContactComponent />
    </Layout>
  );
};

export default ServiceD;
