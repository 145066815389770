import TextBannerComponent from "../components/textbanner";
import ServicesBannerComponent from "../components/servicesbutton";
import ContactComponent from "../components/contact";
import BP from '../images/2.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import Layout from "../components/publicLayout"
import Banner from "../components/banner";
import AlliedHeader from "../components/alliedhealthbanner";

const servicesB = [
  "OT Assistive Technology (Equipment)",
  "Functional Capacity Assessments ",
  "Home Modifications",
  "Sensory Assessments",
  "Housing Support (SDA or SIL)",
  "Wheelchair and Seating",
  "Home safety assessments",
  "Children, youth, and adult therapy services"
];

const Components = () => {
    return (
        <div>
            <Layout>
                <Banner
                    title="Allied Health"
                    content=""
                />
                <AlliedHeader />
              <TextBannerComponent 
                  title="Occupational Therapist"
                  content={<>
                      Occupational Therapy (OT) involves working with clients of all ages and abilities to improve independence, manage day to day tasks and achieve life goals. We offer support related to performing tasks such as showering, cooking, living at home independently, shopping, learning or working. We help you engage in all the activities that matter to you the most. 
                      <br /><br />
                      We provide a wide range of NDIS BSP services including:
                      <ul className="list-none pl-0 space-y-2 text-gray-800">
                        {servicesB.map(service => (
                          <li key={service} className="flex items-start">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-indigo-600 mr-3 mt-1.5" />
                            <span>{service}</span>
                          </li>
                        ))}
                      </ul>
                                    </>}
                  image={BP}
              />
              <ServicesBannerComponent />
              <ContactComponent />
            </Layout>
        </div>
    );
}

export default Components;