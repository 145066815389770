import axios from 'axios';

interface ContactData {
  fullName: string | null;
  postcode: number | null;
  email: string;
  phone: string | null;
  msg: string | null;
}

export const sendContactMessage = async (data: ContactData) => {
  try {
    const response = await axios.post('msg/newmsg', data);
    console.log('Message sent successfully:', response.data);
    alert("Message sent successfully!");
  } catch (error) {
    console.error('Failed to send message:', error);
    alert("Failed to send message.");
  }
};

export const fetchMessages = async () => {
  try {
    const response = await axios.get('/msg/list');
    return response.data.data;
  } catch (error) {
    console.error('Failed to fetch messages:', error);
    throw error;
  }
};

export const deleteMessage = async (id: number) => {
  try {
    const response = await axios.post('/msg/delete', { id });
    if (response.data.code === 0) {
      alert('Delete successful');
    } else {
      alert('Failed to delete');
    }
  } catch (error) {
    console.error('Delete failed:', error);
    alert('Error during deletion');
  }
};
